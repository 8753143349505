* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Nunito Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  display: block; /* iframes are inline by default */
  background: #fff;
  border: none; /* Reset default border */
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.col-4 {
  width: 33.33%;
  padding: 0 10px;
}

.col-3 {
  width: 25%;
  padding: 0 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mbi-88 {
  margin-top: calc(88px + 38px) !important;
}

.relative {
  position: relative;
}

.hidden {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .col-4 {
    width: 50%;
  }
}

@media screen and (max-width: 414px) {
  .col-4 {
    width: 100%;
  }
}
