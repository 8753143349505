.footer {
  background: #f9f9f9;
  padding-top: 64px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

:fullscreen {
  .footer {
    z-index: 1;
  }
}

.social {
  margin-top: 48px;

  a {
    display: inline-flex;
    width: 40px;
    height: 40px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 56px;

  .logo {
    width: 184px;

    img {
      margin-bottom: 12px;
      width: 166px;
    }
    h4 {
      color: #666;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .menuMap {
    width: 667px;
    .menuSplit {
      display: flex;
      justify-content: space-between;
    }
    h3 {
      color: #070707;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 6px;
      li {
        a {
          text-decoration: none;
          color: #777;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover {
            text-decoration: underline;
            color: #ef3858;
          }
        }
      }
    }
  }
}

.bottom {
  padding-top: 24px;
  border-top: 1px solid #f3f3f3;
  text-align: center;

  span {
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.menuContainer {
  position: relative;
}

.expandIconMobile {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.socialMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .top {
    flex-direction: column;
    padding-bottom: 24px;

    .logo {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 24px;

      .social {
        display: none;
      }
    }

    .menuMap {
      width: 100%;
      padding: 0 24px;

      .menuSplit {
        flex-direction: column;
      }
    }

    .menuContainer {
      padding: 16px 0;
      overflow: hidden;
      border-top: 1px solid #f3f3f3;

      &:last-child {
        border-bottom: 1px solid #f3f3f3;
      }

      .item {
        max-height: 19px;
        transition: all 0.3s ease;
      }
    }

    .expandIconMobile {
      display: block;
    }
  }

  .bottom {
    border: none;

    .socialMobile {
      display: block;
      margin-bottom: 12px;
    }

    span {
      padding: 0 38px;
      display: block;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 0;

    a {
      width: 20px;
      height: 20px;
    }
  }
}
