.customContainer {
  height: 100%;
}

.home {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 24px;
  padding-right: 24px;
}

.loginContainer {
  width: 370px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loginButton {
  border-radius: 8px;
  background: #ef3858;
  display: flex;
  width: 368px;
  padding: 13px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  width: 100%;

  > span {
    color: #fff;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:hover {
    background: #dd2443;
  }

  &:disabled {
    background-color: #f1f1f1 !important;
    pointer-events: none;
    > span {
      color: #202020;
    }
  }
}

.loginForm {
  h1 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.textNotify {
  color: #fff;
}

@media screen and (max-width: 414px) {
  .home {
    background-position: 52%;
  }
  .loginContainer {
    transform: translate(-50%, -50%);
    left: 50%;
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .loginContainer {
    width: 270px;
  }
}
