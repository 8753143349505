.header {
  height: 88px;
  position: fixed;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 100;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-100px);
  top: 0;

  &.show,
  &.forceShow {
    transform: translateY(0);
  }
}

:fullscreen .header {
  z-index: 0;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  cursor: pointer;
}

.menu {
  display: flex;
  gap: 12px;
  align-items: center;

  i {
    font-size: 24px;
  }

  span {
    color: #0f0f0f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 42px;
  }
}

.rightContent {
  display: flex;
  align-items: center;
  gap: 24px;
}

.langContainer {
  position: relative;
  .langTitle {
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      width: 24px;
    }
  }
  .langChoice {
    position: absolute;
    top: 32px;
    left: 0;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: visible;
    }

    ul {
      background-color: #fff;
      min-width: 150px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
        rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
        rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

      li {
        padding: 8px;
        display: flex;
        gap: 8px;

        svg {
          width: 24px;
        }
        &:hover {
          background-color: #ffabb9;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .menu {
    span {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .box {
    padding: 0 12px;
  }
}
