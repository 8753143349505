.formGroup {
  margin-bottom: 24px;

  .label {
    color: #fff;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
  }
  .input {
    > input {
      border-radius: 8px;
      border: 1px solid #efefef;
      background: #f1f1f1;
      height: 48px;
      outline: none;
      width: 100%;
      padding: 13px 20px;
    }
  }
}
